import { PreloadLapiReviewDTO } from 'api/lapiReview/types';

// Handler type called when a review is to be presented
export type PresentReviewHandler = {
  (presentPreloadLapiReviewDTO: PreloadLapiReviewDTO): void;
};

// Handler type called when no next reviews are available
export type NoAvailableReviewHandler = {
  (): void;
};

// Handler type called when a dequeued review has timed out
export type PresentedReviewTimeoutHandler = {
  (presentedControlIdTimeout: string): void;
};

// Internal typedef for setTimeout with .bind() type resolution in typescript
type InternalTimeoutCallbackBound = {
  (): void;
};

// Signature to load a review:
// depending on the queue instance we may load from different routes
export type PreloadFetcher = {
  (): Promise<PreloadLapiReviewDTO>;
};

class LapiReviewPreloadQueue {
  // Queue container
  private name: string;
  private maxQueueSize: number;
  private queue: Array<PreloadLapiReviewDTO>;
  private autoRefillOnDequeue: boolean;

  // Fetcher method
  private preloadFetcher: PreloadFetcher;

  // Registered handlers
  private presentReviewHandlers: Array<PresentReviewHandler>;
  private noAvailableReviewHandlers: Array<NoAvailableReviewHandler>;
  private presentedReviewTimeoutHandlers: Array<PresentedReviewTimeoutHandler>;

  // Background timeout process
  private queueRunning: boolean;
  private reviewsTimeoutEnabled: boolean;
  private reviewsTimeoutReplace: boolean;
  private reviewsTimeoutTimers: { [reviewControlId: string]: number };

  // Logs
  private debugLogs: boolean;

  constructor(
    name: string,
    fetcher: PreloadFetcher,
    maxQueueSize: number,
    autoRefill: boolean,
    timeoutEnabled: boolean,
    timeoutReplace: boolean
  ) {
    this.name = name;
    this.maxQueueSize = maxQueueSize;
    this.queue = [];
    this.preloadFetcher = fetcher;
    this.autoRefillOnDequeue = autoRefill;

    this.presentReviewHandlers = [];
    this.noAvailableReviewHandlers = [];
    this.presentedReviewTimeoutHandlers = [];

    this.queueRunning = false;
    this.reviewsTimeoutEnabled = timeoutEnabled;
    this.reviewsTimeoutReplace = timeoutReplace;
    this.reviewsTimeoutTimers = {};

    this.debugLogs = false; // Don't forget to false
  }

  // Internal logger is handy but MUST be used only for dev purposes
  private log(...args: any[]): void {
    if (this.debugLogs) {
      // eslint-disable-next-line no-console
      console.debug(`[Queue ${this.name}]:`, ...args);
    }
  }

  private reviewLoad(): Promise<PreloadLapiReviewDTO> {
    return new Promise((resolve, reject) => {
      this.preloadFetcher()
        .then(fetchedLapiReviewPreload => {
          const reviewControlId = fetchedLapiReviewPreload.lapiReview.controlId;
          const reviewPlate =
            fetchedLapiReviewPreload.lapiReview.licensePlate.plate;
          this.log(
            'Will push in queue:',
            reviewPlate,
            reviewControlId,
            this.queue.length
          );
          this.queue.push(fetchedLapiReviewPreload);
          if (this.queueRunning && this.reviewsTimeoutEnabled) {
            this.reviewTimeoutSetup(fetchedLapiReviewPreload);
          }
          this.log(
            'Has pushed in queue:',
            reviewPlate,
            reviewControlId,
            this.queue.length
          );
          resolve(fetchedLapiReviewPreload);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  private reviewTimeoutClear(reviewControlId: string): void {
    const timerId = this.reviewsTimeoutTimers[reviewControlId];

    if (timerId !== undefined) {
      window.clearTimeout(timerId);
      delete this.reviewsTimeoutTimers[reviewControlId];
    }
  }

  private reviewTimeoutCallback(reviewControlId: string): void {
    const reviewIndex = this.queue.findIndex(
      e => e.lapiReview.controlId === reviewControlId
    );

    // Cleanup timers map
    this.reviewTimeoutClear(reviewControlId);

    this.log('timeoutCallback before:', this.queue.length, reviewControlId);
    if (reviewIndex !== -1) {
      if (reviewIndex === 0) {
        // In front of queue: dispatch event to registered PresentedTimeoutHandler
        this.presentedReviewTimeoutHandlers.forEach(h => h(reviewControlId));
      }

      // Remove from queue
      this.queue.splice(reviewIndex, 1);

      // Trigger background async loading to try to replace it
      if (this.queueRunning && this.reviewsTimeoutReplace) {
        void this.reviewLoad();
      }
    } else {
      this.log(
        'timeoutCallback was not in queue:',
        this.queue.length,
        reviewControlId
      );
    }
    this.log('timeoutCallback after:', this.queue.length, reviewControlId);
  }

  private reviewTimeoutSetup(preLoadLapiReview: PreloadLapiReviewDTO): void {
    const timeoutDelayMs = 15 * 60 * 1000; // 15 mins
    const reviewControlId = preLoadLapiReview.lapiReview.controlId;

    // Create timeout
    const timeoutCallback = this.reviewTimeoutCallback.bind(
      this,
      reviewControlId
    ) as InternalTimeoutCallbackBound;
    const timerId = window.setTimeout(timeoutCallback, timeoutDelayMs);

    // Save it to map
    this.reviewsTimeoutTimers[reviewControlId] = timerId;
  }

  // Event handlers registration: PresentReviewHandler
  public onPresentReview(handler: PresentReviewHandler): void {
    this.presentReviewHandlers.push(handler);
  }

  // Event handlers registration: NoAvailableReviewHandler
  public onNoAvailableReview(handler: NoAvailableReviewHandler): void {
    this.noAvailableReviewHandlers.push(handler);
  }

  // Event handlers registration: PresentedReviewTimeoutHandler
  public onPresentedReviewTimeout(
    handler: PresentedReviewTimeoutHandler
  ): void {
    this.presentedReviewTimeoutHandlers.push(handler);
  }

  // Trigger events onPresentReview/onNoAvailableReview depending on queue size
  public triggerPresentNextReview(): void {
    this.log('tiggerNext queue size:', this.queue.length);

    // Dequeue current review, if any
    const prevReview = this.queue.shift();
    this.log(
      'tiggerNext prevReview:',
      prevReview?.lapiReview.licensePlate.plate,
      prevReview
    );
    // Clear timer of currently presented review, if any
    if (prevReview !== undefined) {
      this.reviewTimeoutClear(prevReview.lapiReview.controlId);
    }

    // Inspect queue
    if (this.queue.length > 0) {
      const nextReview = this.queue[0];
      this.log(
        'tiggerNext nextReview:',
        nextReview.lapiReview.licensePlate.plate,
        nextReview
      );
      // Type narrow down, always true here
      if (nextReview !== undefined) {
        // Try to keep the queue filled
        if (this.queueRunning && this.autoRefillOnDequeue) {
          void this.reviewLoad();
        }

        // Dispatch present event
        this.presentReviewHandlers.forEach(h => {
          h(nextReview);
        });
      }
    } else {
      // Dispatch no available event
      this.noAvailableReviewHandlers.forEach(h => h());
      this.preloadStop();
    }
  }

  public async preloadStart(): Promise<void> {
    this.queueRunning = true;
    const queueSizeOnTrigger = this.queue.length;
    const queueSlotsAvailable = this.maxQueueSize - queueSizeOnTrigger;
    this.log('preloadStart:', queueSizeOnTrigger);

    if (queueSlotsAvailable > 0) {
      // Fill up the queue
      const loadPromises: Array<Promise<PreloadLapiReviewDTO>> = [];
      for (let i = 0; i < queueSlotsAvailable; i += 1) {
        const loadPromise: Promise<PreloadLapiReviewDTO> = this.reviewLoad();
        loadPromises.push(loadPromise);
      }

      // Queue was empty
      if (queueSizeOnTrigger === 0) {
        // Wait for queue to go from empty to at least one element synchronously
        let oneLoaded = false;
        do {
          const firstPromise = loadPromises.shift();
          if (firstPromise) {
            try {
              // I would use Promise.any instead, but we need esnext
              // eslint-disable-next-line no-await-in-loop
              const loaded = await firstPromise;
              this.log('First one is loaded:', loaded);
              oneLoaded = true;
              // Try to keep the queue filled
              if (this.queueRunning && this.autoRefillOnDequeue) {
                void this.reviewLoad();
              }
              // Present first element in queue, could be another review
              this.presentReviewHandlers.forEach(h => h(this.queue[0]));
            } catch (error) {
              // Discard
            }
          }
        } while (loadPromises.length && !oneLoaded);

        if (!oneLoaded) {
          // Dispatch no available event
          this.log('None loaded at preloadStart');
          this.preloadStop();
          this.noAvailableReviewHandlers.forEach(h => h());
        }
        // Return here: we already triggered the appropriate event
        return;
      }
    }

    // Queue was not empty
    if (this.queue.length > 0) {
      const firstReviewInQueue = this.queue[0];
      // We can dispatch loadedFirst event straight away
      this.presentReviewHandlers.forEach(h => h(firstReviewInQueue));
    }
  }

  public preloadStop(): void {
    this.log('preloadStop');
    this.queueRunning = false;
  }

  public preloadClear(): void {
    this.log('preloadClear');
    this.queueRunning = false;

    let preloaded;
    do {
      preloaded = this.queue.shift();
      if (preloaded !== undefined) {
        const reviewControlId = preloaded.lapiReview.controlId;
        this.reviewTimeoutClear(reviewControlId);
      }
    } while (preloaded !== undefined);
  }
}

export default LapiReviewPreloadQueue;
