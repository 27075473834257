import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { Config, getConfigState } from 'config/duck';
import { Button, LabelValue, MapTooltip, Title } from '@cvfm-front/commons-ui';
import ConfirmAction from 'commons/ConfirmAction';
import { InteractiveMode } from 'commons/services/ParkingSpace/ParkingSpaceMapService';
import { getApiState } from 'api/duck';
import { EditionType } from 'commons/services/ParkingSpace/ParkingSpaceEditionService';
import { formatAddress } from '@cvfm-front/tefps-utils';
import { MapId } from 'commons/services/MapService';

import { computeParkingSpaceCurrentVersion } from './utils';

interface ParkingSpaceMapProps {
  config: Config;
  canEdit: boolean;
}

const { _tg } = window.loadTranslations(__filename);

const ParkingSpaceMap = ({ config, canEdit }: ParkingSpaceMapProps) => {
  const mapRef = useRef(null);

  /*
   * Watchers
   */
  const selectedParkingSpace = useWatcher(
    services.parkingSpaceMap.watchSeletected,
    null
  );
  const isEditingPolygon = useWatcher(
    services.parkingSpaceMap.watchIsEditingPolygon,
    false
  );
  const parkingSpaceWithNewPolygons = useWatcher(
    services.parkingSpaceMap.watchParkingSpaceWithNewPolygons,
    null
  );

  const neutralizationSelection = useWatcher(
    services.parkingSpaceChangeRegime.watchSelection,
    null
  );

  const isSelectingForRegimeChange = useWatcher(
    services.parkingSpaceChangeRegime.watchIsSelecting,
    null
  );

  const zoning = useWatcher(services.zoning.watchZoning, null);

  const lastVersion = useMemo(() => {
    if (selectedParkingSpace && selectedParkingSpace.versions?.length > 0) {
      return computeParkingSpaceCurrentVersion(selectedParkingSpace);
    }
    return null;
  }, [selectedParkingSpace]);

  const latitudeLongitudeString = useMemo(() => {
    if (selectedParkingSpace === null) {
      return '';
    }
    return `${selectedParkingSpace.latLong.latitude},${selectedParkingSpace.latLong.longitude}`;
  }, [selectedParkingSpace]);

  /*
   * Callbacks
   */
  const handleEditVersions = useCallback(() => {
    if (selectedParkingSpace === null) {
      return;
    }

    services.parkingSpaceEdition.startEdition(
      selectedParkingSpace,
      EditionType.VERSION_EDITION
    );
  }, [selectedParkingSpace]);

  const handleDuplicateParkingSpace = useCallback(() => {
    if (selectedParkingSpace === null) {
      return;
    }
    const duplicatedParkingSpace = services.parkingSpaceDuplication.duplicateParkingSpace(
      selectedParkingSpace
    );
    services.parkingSpaceEdition.startEdition(
      duplicatedParkingSpace,
      EditionType.DUPLICATION
    );
  }, [selectedParkingSpace]);

  const deleteParkingSpace = useCallback(async () => {
    if (selectedParkingSpace === null) {
      return;
    }

    await services.parkingSpaceDeletion.deleteParkingSpace(
      selectedParkingSpace.parkingSpaceId
    );
    setTimeout(() => {
      void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
    }, 1000);
  }, [selectedParkingSpace]);

  const handleNeutralization = useCallback(() => {
    services.parkingSpaceChangeRegime.setIsChangingRegime(true);
  }, []);

  /*
   * Effects
   */
  useEffect(() => {
    if (mapRef.current === null) {
      return;
    }

    void services.mapService.init(MapId.PARKING_SPACE, config, mapRef);
  }, [mapRef]);

  useEffect(() => {
    services.mapService.get(MapId.PARKING_SPACE)?.clear?.map();
    services.parkingSpaceMap.setInteractiveMode(InteractiveMode.EDIT);
    services.parkingSpaceMap.setupAutoRefresh(MapId.PARKING_SPACE);
    return () =>
      services.parkingSpaceMap.cancelAutoRefresh(MapId.PARKING_SPACE);
  }, []);

  /*
   * Render
   */
  return (
    <div
      className="parking-space"
      style={{ position: 'relative', height: '100%' }}
    >
      <div ref={mapRef} style={{ height: '100%' }} />
      {selectedParkingSpace !== null &&
        isSelectingForRegimeChange === false &&
        isEditingPolygon === false && (
          <MapTooltip>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Title>{selectedParkingSpace.parkingSpaceId}</Title>
              <Button
                className="v2button--borderless"
                onClick={(): void => {
                  services.parkingSpaceMap.closeSelection(MapId.PARKING_SPACE);
                }}
              >
                <div style={{ fontSize: 'xx-large' }}>&#215;</div>
              </Button>
            </div>
            <LabelValue
              label={_tg('id')}
              value={selectedParkingSpace.parkingSpaceId}
            />
            <LabelValue
              label={_tg('groupId')}
              value={selectedParkingSpace.parkingSpaceAreaId ?? ''}
            />
            <LabelValue
              label={_tg('zoneId')}
              value={
                zoning?.zones.find(
                  zone => zone.id === selectedParkingSpace.zoneId
                )?.name || ''
              }
            />
            {lastVersion && (
              <LabelValue
                label={_tg('capacity')}
                value={lastVersion.parkingSpacesNumber.toString()}
              />
            )}
            {lastVersion && (
              <LabelValue
                label={_tg('type')}
                value={_tg(
                  `ParkingOrientation.${lastVersion?.parkingType ?? 'BATTLE'}`
                )}
              />
            )}
            {lastVersion && (
              <LabelValue
                label={_tg('regime')}
                value={_tg(
                  `ParkingSpaceRegime.${lastVersion?.parkingRegime ??
                    'UNKNOWN'}`
                )}
              />
            )}
            <LabelValue
              label={`${_tg('latitude')}, ${_tg('longitude')}`}
              value={latitudeLongitudeString}
            />
            {selectedParkingSpace.address && (
              <LabelValue
                label={_tg('address')}
                value={formatAddress(selectedParkingSpace.address).join(' ')}
              />
            )}
            <BoButton
              primary
              label={_tg('editVersions')}
              onClick={handleEditVersions}
              disabled={!canEdit}
            />
            <BoButton
              label={_tg('duplicateParkingSpace')}
              onClick={handleDuplicateParkingSpace}
              disabled={!canEdit}
            />
            <ConfirmAction
              enabled
              message={_tg('deleteParkingSpaceConfirmation')}
              action={deleteParkingSpace}
            >
              <BoButton
                fullWidth
                secondary
                label={_tg('deleteParkingSpace')}
                disabled={!canEdit}
              />
            </ConfirmAction>
          </MapTooltip>
        )}
      {isSelectingForRegimeChange === true && (
        <MapTooltip>
          <Title>{`${_tg('selection')} (${neutralizationSelection?.length ??
            0})`}</Title>
          <BoButton
            primary
            label={_tg('changeRegimes')}
            onClick={handleNeutralization}
          />
          <BoButton
            secondary
            label={_tg('cancel')}
            onClick={() => {
              services.parkingSpaceChangeRegime.reset();
            }}
          />
        </MapTooltip>
      )}
      {isEditingPolygon === true && parkingSpaceWithNewPolygons !== null && (
        <MapTooltip>
          <Title>{_tg('editionOfPolygon')}</Title>
          <BoButton
            primary
            label={_tg('save')}
            onClick={async () => {
              if (selectedParkingSpace === null) {
                return;
              }

              const localizedAddress = await services.geocodingApi.reverseGeocode(
                {
                  latitude:
                    parkingSpaceWithNewPolygons.geometry.coordinates[0][0][1],
                  longitude:
                    parkingSpaceWithNewPolygons.geometry.coordinates[0][0][0],
                },
                parkingSpaceWithNewPolygons.cityId
              );
              parkingSpaceWithNewPolygons.address = localizedAddress.address;
              const response = await services.parkingSpaceApi.updateParkingSpace(
                parkingSpaceWithNewPolygons
              );
              services.parkingSpaceMap.selectParkingSpace(
                MapId.PARKING_SPACE,
                response
              );
              services.parkingSpaceMap.setIsEditingPolygon(false);
            }}
          />
          <BoButton
            secondary
            label={_tg('cancel')}
            onClick={() => {
              services.parkingSpaceMap.setIsEditingPolygon(false);
              void services.parkingSpaceMap.refresh(MapId.PARKING_SPACE);
            }}
          />
        </MapTooltip>
      )}
    </div>
  );
};

// TODO: We need a CityService that is always init, and use it in useEffect
export default connect(state => {
  const config = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    config,
    canEdit: userInfo && userInfo.rights.includes('PARKING_SPACE_WRITE'),
  };
})(ParkingSpaceMap);
