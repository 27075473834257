import React, { CSSProperties } from 'react';
import Raised from 'material-ui/RaisedButton';
import StreetViewIcon from 'material-ui/svg-icons/maps/streetview';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { MuiTheme } from 'material-ui/styles';

import ClipBoardButton from 'commons/ClipBoardButton';
import { Coordinates } from 'api/commonTypes';

const { _tg } = window.loadTranslations(__filename);

const CONTAINER_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column-reverse',
  paddingLeft: 20,
  flex: 1,
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginTop: 10,
};

const LEGEND_SPAN_STYLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const ADDRESS_CIRCLE: CSSProperties = {
  width: 10,
  height: 10,
  backgroundColor: 'rgba(102, 0, 153, 0.2)',
  border: '1px solid #660099',
  borderRadius: '50%',
  margin: 6.5,
};

type Props = {
  controlAddressCoordinates: Coordinates;
  controlPositionCoordinates: Coordinates;
  controlDirectionPresent?: boolean;
  controlProjection?: Coordinates;
  muiTheme?: MuiTheme;
};

const Legend = ({
  controlAddressCoordinates,
  controlPositionCoordinates,
  controlDirectionPresent,
  controlProjection,
  muiTheme,
}: Props) => {
  const addressCoordinatesText = `${controlAddressCoordinates.latitude},${controlAddressCoordinates.longitude}`;
  const positionCoordinatesText = `${controlPositionCoordinates.latitude},${controlPositionCoordinates.longitude}`;
  const projectionCoordinatesText = controlProjection
    ? `${controlProjection.latitude},${controlProjection.longitude}`
    : undefined;
  const openStreetView = () => {
    window.open(
      `http://maps.google.com/maps?q=&layer=c&cbll=${addressCoordinatesText}`,
      'Street_View'
    );
  };

  return (
    <div style={CONTAINER_STYLE}>
      <div style={ROW_STYLE}>
        <Raised
          label="Street view"
          onClick={openStreetView}
          icon={<StreetViewIcon />}
          fullWidth
        />
      </div>
      {controlProjection && (
        <div style={ROW_STYLE}>
          <img src="/static/img/map/circles/circle_ko.png" />
          <span style={LEGEND_SPAN_STYLE}>
            <span style={{ color: muiTheme?.textField?.textColor }}>
              {_tg(
                'tefps.lapiReview.geolocalisation.components.legend.controlProjection'
              )}
            </span>
            <ClipBoardButton
              subject={_tg(
                'tefps.lapiReview.geolocalisation.components.legend.controlProjection'
              )}
              textToClip={projectionCoordinatesText as string} // can't be undefined, as controlProjection is defined here
              plural
              feminine
            />
          </span>
        </div>
      )}
      {controlDirectionPresent && (
        <div style={ROW_STYLE}>
          <img src="/static/img/map/circles/circle_exemption.png" />
          <span style={LEGEND_SPAN_STYLE}>
            <span style={{ color: muiTheme?.textField?.textColor }}>
              {_tg(
                'tefps.lapiReview.geolocalisation.components.legend.controlDirection'
              )}
            </span>
          </span>
        </div>
      )}
      <div style={ROW_STYLE}>
        <img src="/static/img/map/circles/circle_address.png" />
        <span style={LEGEND_SPAN_STYLE}>
          <span style={{ color: muiTheme?.textField?.textColor }}>
            {_tg(
              'tefps.lapiReview.geolocalisation.components.legend.controlAddress'
            )}
          </span>
          <ClipBoardButton
            subject={"Les coordonnées de l'adresse"}
            textToClip={addressCoordinatesText}
            plural
            feminine
          />
        </span>
      </div>
      <div style={ROW_STYLE}>
        <img src="/static/img/map/circles/circle_position.png" />
        <span style={LEGEND_SPAN_STYLE}>
          <span style={{ color: muiTheme?.textField?.textColor }}>
            {_tg(
              'tefps.lapiReview.geolocalisation.components.legend.controlPosition'
            )}
          </span>
          <ClipBoardButton
            subject="Les coordonnées de la position"
            textToClip={positionCoordinatesText}
            plural
            feminine
          />
        </span>
      </div>
      <div style={ROW_STYLE}>
        <div style={ADDRESS_CIRCLE} />

        <span style={LEGEND_SPAN_STYLE}>
          <span style={{ color: muiTheme?.textField?.textColor }}>
            {_tg(
              'tefps.lapiReview.geolocalisation.components.legend.banAdresses'
            )}
          </span>
        </span>
      </div>
    </div>
  );
};

export default muiThemeable()(Legend);
